import { createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "block shadowRadiusCard discoverCard" }
const _hoisted_2 = { class: "infoContainer" }
const _hoisted_3 = { class: "text-uppercase fs_14 greyBlue ln_12 title" }
const _hoisted_4 = { class: "fs_11 greyBlue d-block ln_12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: "backgroundImage",
      style: 'background-image: url(\'' + _ctx.item.backgroundImage + '\');'
    }, null, 4),
    _createVNode("div", {
      class: "logoImage",
      style: 'background-image: url(\'' + _ctx.item.logo + '\');'
    }, null, 4),
    _createVNode("div", _hoisted_2, [
      _createVNode("span", _hoisted_3, _toDisplayString(_ctx.item.title), 1),
      _createVNode("span", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.item.likes) + " ", 1),
        _createVNode(_component_localized_text, {
          class: "ms-1",
          localizedKey: "app_iscritti",
          text: "iscritti"
        })
      ])
    ])
  ]))
}