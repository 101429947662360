
import { Options, Vue } from 'vue-class-component';
import { UserClient, FriendRequestClient, ConversationRoomClient, CommunityClient, AuthClient } from '@/services/Services';
import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import CommunityListModal from '../modals/communityListModal.vue';
import EditInfo from '../modals/editProfileInfoModal.vue';
import InvitationModal from '../modals/invitationModal.vue';
import ChangePasswordModal from '../modals/changePasswordModal.vue';
import UsersBlocked from '../modals/usersBlocked.vue';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { LoginServices } from '@/services/LoginServices';
import ConfirmModal from '@/components/confirmModal.vue';
import ChatSettingsModal from '@/views/chat/modals/chatSettingsModal.vue';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class Profile extends Vue {

    profile: OM.UserProfileVm = null;
    
    isSelf: boolean = false;
    loading: boolean = false;

    created() {
        this.init();
    }

    init() {
        this.isSelf = store.state.user.identifier == this.$route.params.identifier.toString();

        store.state.globalSpinner++;
        this.loading = true;
        UserClient.getProfile(this.$route.params.identifier.toString())
        .then(x => {
            this.profile = x;
            StorageServices.setSelectedUser({ name: this.profile.name, identifier: this.profile.identifier });
            store.state.selectedUser = { name: this.profile.name, identifier: this.profile.identifier };
        })
        .finally(() => {
            store.state.globalSpinner--;
            this.loading = false;
        });
    }

    info() {
        this.$opModal.show(EditInfo, {
            userIdentifier: this.profile.identifier
        }, () => this.init())
    }

    categories() {
        this.$router.push('/categories/true');
    }

    myPlaces() {
        this.$router.push('/profile/' + this.profile.identifier + '/places');
    }

    ordini() {
        this.$router.push('/order-list/');
    }

    invitation(){
        this.$opModal.show(InvitationModal, {
            userIdentifier: this.profile.identifier
        })
    }

    changePassword(){
        UserClient.hasOldPassword()
        .then( x => {
            this.$opModal.show(ChangePasswordModal, {
                hasOldPassword: x,
                callback: (vm: OM.ChangeCustomPasswordVM) => {
                    AuthClient.changeCustomPassword(vm)
                    .then( () => {
                        var message = LocalizationServices.getLocalizedValue("app_passwordcambiataconsuccesso",  "Password cambiata con successo!");
                        this.$opModal.closeLast();
                        this.$opModal.show(ModalSuccessError, {
                            message: message,
                        })
                    }).catch( x => {
                        var buttonText = LocalizationServices.getLocalizedValue("app_ok", "Ok");

                        this.$opModal.show(ModalSuccessError, { 
                            isError: true,
                            message: x.message, 
                            buttonText: buttonText,
                        });
                    })
                }
            })
        })
    }

    logout() {
        LoginServices.logout();
    }

    message() {
        Promise.all([
            FriendRequestClient.checkRequestSent(this.profile.identifier),
            ConversationRoomClient.checkConversationExisting(this.profile.identifier),
        ])
        .then(xs => {
            if(!xs[0] && !xs[1]){
                this.$router.push('/chats/newRequest/' + this.profile.identifier);
            } else if(xs[0]){
                this.$router.push('/chats/request/' + xs[0]);
            } else if(xs[1]){
                this.$router.push('/chats/' + xs[1]);
            }
        })
    }

    openCommunityLiked(){
        CommunityClient.getUserCommunityLiked(this.$route.params.identifier.toString())
        .then( x => {
            var text = LocalizationServices.getLocalizedValue("app_communityseguite", "Community seguite");
            this.$opModal.show(CommunityListModal,{
                communityList: x,
                title: text
            })
        }).catch( err => {
            window.toastr.error(err.message);
        })
    }

    openCommunityManaged(){
        CommunityClient.getUserCommunityManaged(this.$route.params.identifier.toString())
        .then( x => {
            var text = LocalizationServices.getLocalizedValue("app_communitygestite", "Community gestite");
            this.$opModal.show(CommunityListModal,{
                communityList: x,
                title: text,
                canCreate: true
            })
        }).catch( err => {
            window.toastr.error(err.message);
        })
    }

    get hasNewInvitation() {
        return store.state.newInvitationCount > 0;
    }

    openUsersBlockedModal(){
        this.$opModal.show(UsersBlocked, {
            saveCb: (data: VM.NameIdentifierSelected[]) => {
                var vm: OM.SetListVmOfGuid = new OM.SetListVmOfGuid();
                vm.values = data.map( x => x.identifier);

                UserClient.unBlockUser(vm) 
                .then( x => {
                    this.$opModal.closeLast();
                    var text = LocalizationServices.getLocalizedValue("app_utenteabilitatoconsuccesso", "Utente abilitato con successo!");

                    window.toastr.success(text)
                }).catch( err => {
                    window.toastr.error(err.message)
                })
            }
        });
    }

    deleteUserModal(){
        this.$opModal.show(ConfirmModal, {
            text: LocalizationServices.getLocalizedValue("app_seiSicuroDiVolerEliminareLAccount?", "Sei sicuro di voler eliminare l'account?"), 
            okCb: (x: boolean) => {
                UserClient.deleteAccount()
                .then( x => {
                    this.$opModal.closeLast();
                    LoginServices.logout();
                })
            }
        })
    }

    report(){
        this.$opModal.show(ChatSettingsModal, {
            chatName: this.profile.name,
            userIdentifier: this.profile.identifier,
            buttonUrl: ""
        })
    }
}

