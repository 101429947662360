
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ApprovationRequestClient } from '@/services/Services';
import store from '@/store';
import * as OM from '@/model';

@Options({})
export default class InvitationModal extends Vue {

    @Prop() userIdentifier: string;

    list: OM.RequestVM[] = [];

    created(){
        ApprovationRequestClient.getMyInvitationRequest()
        .then( x => {
            this.list = x;
        })
    }

    accept(item: OM.RequestVM){
        ApprovationRequestClient.acceptInvitation(item.identifier)
        .then( x => {
            if(store.state.newInvitationCount > 0)
                store.state.newInvitationCount --;
            
            var index = this.list.indexOf(item);
            this.list.splice(index, 1);
            window.toastr.success("Invito accettato!")
        }).catch( err => {
            window.toastr.error(err.message)
        })
    }

    deny(item: OM.RequestVM){
        ApprovationRequestClient.voidInvitation(item.identifier)
        .then( x => {
            if(store.state.newInvitationCount > 0)
                store.state.newInvitationCount --;
            
            var index = this.list.indexOf(item);
            this.list.splice(index, 1);
            window.toastr.success("Invito rifiutato!")
        }).catch( err => {
            window.toastr.error(err.message)
        })
    }

}

