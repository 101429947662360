
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { checkPassword } from '@/utils';
import * as OM from '@/model';

@Options({})
export default class ChangePasswordModal extends Vue {

    @Prop() callback: any;
    @Prop({
        default: true
    }) hasOldPassword: boolean;

    repeatPassword: string = "";
    changePassword: OM.ChangeCustomPasswordVM = new OM.ChangeCustomPasswordVM();

    created(){

    }

    get disableButton(){
        return (!this.changePassword.oldPassword.trim() && this.hasOldPassword) || !this.changePassword.newPassword.trim() || 
            !(this.changePassword.newPassword == this.repeatPassword) || !checkPassword(this.changePassword.newPassword);
    }

    confirm(){
        this.callback(this.changePassword);
    }

}

