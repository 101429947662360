
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { UserClient } from '@/services/Services';
import { getUniversalDate, getUniversalISOStringDate } from '@/utils';
import FileInput from '@/components/fileInput.vue';
import UoseWriter from '@/components/uoseWriter.vue';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Options({
    components: {
        FileInput,
        UoseWriter
    }
})
export default class EditProfileInfoModal extends Vue {

    @Prop() userIdentifier: string;

    genders: {[value: string] : string} = VM.genders;

    loading: boolean = false;
    profile: OM.UserSelfProfileVm = null;
    vm: VM.FileIdentifier = new VM.FileIdentifier();
    isCordova: boolean = !!window.cordova;

    error: string = "";

    created() {
        this.vm.identifier = this.userIdentifier;
        this.init();
    }

    init() {
        this.loading = true;
        UserClient.getSelfProfile(this.userIdentifier)
        .then(x => {
            this.profile = x;
            if(x.birthDate != null)
                this.profile.birthDate = <any>getUniversalDate(x.birthDate);
            else
                this.profile.birthDate = null;
        })
        .finally(() => this.loading = false);
    }
    
    @Watch('vm.file')
    onFileChange(next, prev) {
        debugger;
        if(this.vm.file){
            store.state.globalSpinner++;
            UserClient.saveProfileImage(this.vm.identifier,this.vm.file)
            .then(x => {
                if(store.state.userImageRefresher[store.state.user.identifier]){
                    store.state.userImageRefresher[store.state.user.identifier]++;
                } else {
                    store.state.userImageRefresher[store.state.user.identifier] = 1;
                }
                this.init();
            })
            .finally(() => store.state.globalSpinner--)
        }
    }

    save() {
        this.loading = true;
        Promise.all([
            UserClient.updateUserName({ identifier: this.profile.identifier, name: this.profile.name }),
            UserClient.updateSurname({ identifier: this.profile.identifier, name: this.profile.surname }),
            UserClient.updateUserNumber({ identifier: this.profile.identifier, name: this.profile.telephone ? this.profile.telephone : "" }),
            UserClient.updateUserEmail({ identifier: this.profile.identifier, name: this.profile.email }),
            UserClient.updateBirthDate({ identifier: this.profile.identifier, date: this.profile.birthDate ? getUniversalISOStringDate(new Date(this.profile.birthDate)) : null }),
            UserClient.updateGender({ identifier: this.profile.identifier, name: this.profile.gender }),
            UserClient.updateUserDescription({ identifier: this.profile.identifier, name: this.profile.description })
        ])
        .then(x => {
            UserClient.checkIfProfileCompleted()
            .then( x => {
                store.state.user.profileCompleted = x;
                this.$emit('close');
            })
        })
        .catch(err => {
            var message = err.message;
            this.error = message;

            setTimeout(() => {
                this.error = "";
            }, 3000);
        })
        .finally(() => this.loading = false);
    }

}

