
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CommunityCard from '@/components/communityCard.vue';
import CreateCommunity from '@/views/communities/modals/createCommunity.vue';
import * as OM from '@/model';

@Options({
    components: {
        CommunityCard
    }
})
export default class CommunityListModal extends Vue {

    @Prop({
        default: () => []
    }) communityList: OM.CommunityListVm[];

    @Prop({
        default: ""
    }) title: string;

    @Prop({
        default: false
    }) canCreate: boolean;

    openCommunity(item: OM.CommunityListVm){
        this.$opModal.closeLast();
        this.$router.push('/communities/' + item.slug + '/Bacheca');
    }

    openCreateCommunity(){
        this.$opModal.show(CreateCommunity, {
            
        });
    }

}

